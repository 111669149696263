<template>
  <div class="auth" id="authPage">
    <div class="wrapper">
      <div class="box">
        <router-link :to="'/'" class="logo_link">
          <img src="@/assets/logo.png" alt="Glass ERP" class="logo_img">
          <span class="logo_text">GlassERP</span>
        </router-link>
        <el-divider></el-divider>
        <h3>Авторизация</h3>
        <p class="help_text">
          Введите номер телефона, мы отправим вам смс сообщение с кодом доступа.
        </p>
        <div v-if="validationErrors" class="errors">
          <p class="error" v-for="error in validationErrors" :key="error">
            {{ error }}
          </p>
        </div>
        <el-form
            :model="form"
            ref="authForm"
            :rules="rules"
            size="normal"
            @submit.native.prevent="onSubmit('authForm')"
            label-position="top">

          <el-form-item prop="phone">
            <label for="">Номер телефона</label>
            <el-input
                v-model="form.phone"
                type="tel"
                v-facade="'+7 (###) ###-####'"
                placeholder="+7 (___) ___-____"
                autofocus
                @keypress.enter="onSubmit('authForm')"
                :disabled="phoneDisabled"
                size="small"
            ></el-input>
          </el-form-item>

          <el-form-item v-if="codeReady">
            <label for="">Код из SMS</label>
            <el-input
                v-model="form.code"
                type="text"
                v-facade="'######'"
                @keypress.enter="onSubmit('authForm')"
                placeholder="______"
                size="small"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
                :loading="loading"
                type="primary"
                size="small"
                @click="onSubmit('authForm')">
              <span v-if="codeReady">Подтвердить</span>
              <span v-else>Получить код</span>
            </el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes} from '@/store/modules/user'

export default {
  name: "Auth",
  data() {
    let validatePhoneLength = (rule, value, callback) => {
      if (value.length < 17) {
        callback(new Error('Введите номер телефона полностью'))
      } else {
        callback()
      }
    }
    let validatePhoneFormat = (rule, value, callback) => {
      if (value[4] != 9) {
        callback(new Error('Введите правильный номер телефона'))
      } else {
        callback()
      }
    }
    return {
      phoneDisabled: false,
      codeReady: false,
      checking: true,
      form: {
        phone: '',
        code: null,
      },
      rules: {
        phone: [
          {
            required: true,
            message: 'Введите номер телефона',
            trigger: 'blur'
          },
          {validator: validatePhoneLength, trigger: 'blur'},
          {validator: validatePhoneFormat, trigger: 'blue'},
        ]
      }
    }
  },
  computed: {
    ...mapState({
      submitting: state => state.user.submitting,
      loading: state => state.user.loading,
      isLoggedIn: state => state.user.isLoggedIn,
      validationErrors: state => state.user.errors,
      currentUser: state => state.user.user
    }),
    showLoader() {
      if (this.isLoggedIn == null) return true
      if (this.isLoggedIn == true) return true
      return false
    },
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.codeReady == false) {
            this.$store
                .dispatch(actionTypes.getCode, {
                  phone: this.form.phone
                })
                .then((response) => {
                  this.codeReady = true
                  this.phoneDisabled = true
                })
          } else {
            this.$store
                .dispatch(actionTypes.confirmCode, {
                  phone: this.form.phone,
                  code: this.form.code
                })
                .then(() => {
                  this.$router.push('/')
                })
          }
        } else {
          return false;
        }
      })
    },
    checkCurrentUser() {
      this.$store
          .dispatch(actionTypes.getUser)
          .then((user) => {
            if (this.$store.state.user.isLoggedIn) {
              this.$router.go(-1)
            } else {
              this.checking = false
            }
          })
    }
  },
  beforeMount() {
    this.checkCurrentUser()
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>

<style lang="scss">
@import "../styles/auth";
</style>
